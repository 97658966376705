import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Icon,
    Link,
    Spacer,
    Spinner,
    Switch,
    Text,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { getToken } from "app/config/firebase";
import useLocalStorage from "app/hooks/useLocalStorage";
import { RootState } from "app/modules";
import { disablePushNotification } from "app/modules/authed";
import { Device } from "app/types/account";
import { browserNameVersion } from "app/utils/browserInfo";
import captureException from "app/utils/captureException";
import { getJSON, postJSON } from "app/utils/fetchUtils";
import { useAccountId } from "app/utils/react-helpers";
import { isSupported } from "firebase/messaging";
import React, { useEffect, useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useSelector } from "react-redux";
interface PushNotificationLinkProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

const PushNotificationLink: React.FC<PushNotificationLinkProps> = ({ ...props }) => {
    const [isReady, setReady] = useState<boolean>(false);
    const accountId = useAccountId();
    const userId = useSelector((state: RootState) => state.authed.user.id);
    const toast = useToast({ isClosable: true, duration: 5000, position: "bottom" });
    const [isPushNotificationSupported, setPushNotificationSupported] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        isSupported().then((e) => setPushNotificationSupported(e));
    }, []);

    const [config] = useLocalStorage<{ iid: string } | undefined>("config", undefined, {});
    const instanceId = config?.iid;
    const [pushConfig, setPushConfig] = useLocalStorage<{
        i?: string;
        u?: string;
        t?: string;
        a?: string;
        s?: "unknown" | "loading" | "success" | "failed" | "disabled";
    }>("pushConfig", { s: "unknown" }, {});

    const reset = () => {
        setPushConfig({ s: "unknown" });
    };
    const disableNotification = async () => {
        await disablePushNotification()
            .then(() => {
                if (!toast.isActive("disable-push-notification")) {
                    toast({
                        id: "disable-push-notification",
                        title: <Trans>Disabled Push Notification</Trans>,
                        description: (
                            <Trans>Push Notification has been disabled now, you can re-enable it when required.</Trans>
                        ),
                        status: "info",
                    });
                }
            })
            .catch((ex) => {
                console.log(ex?.message);
                // captureException(ex);
            })
            .then(() => {
                setPushConfig({ s: "disabled" });
            });
    };

    const getPushToken = async ({ silent = false }: { silent?: boolean }) => {
        if (!instanceId || !accountId || !userId) return;
        const { i, u, a, s, t } = pushConfig;
        // If token are being uploaded, bail out
        if (s === "loading") return;
        return getToken()
            .then(async (token) => {
                if (token === "--NOT-SUPPORTED--") {
                    setPushNotificationSupported(false);
                    return;
                }
                if (token) {
                    if (i === instanceId && u === userId && a === accountId && t === token && s === "success") return;
                    const push = { i: instanceId, u: userId, a: accountId, t: token };
                    setPushConfig({ ...push, s: "loading" });
                    const [browser, browserVersion] = browserNameVersion();

                    await postJSON<Device>(`/api/account/${accountId}/devices`, {
                        os: browser,
                        osVersion: browserVersion,
                        token,
                        instanceId,
                    })
                        .then(() => {
                            setPushConfig({ ...push, s: "success" });
                            if (!silent) {
                                if (!toast.isActive("push-notification")) {
                                    toast({
                                        id: "push-notification",
                                        title: <Trans>Never miss a message!</Trans>,
                                        description: (
                                            <Trans>
                                                Push Notification has been enabled, you will now start receiving
                                                important alerts right here.
                                            </Trans>
                                        ),
                                        status: "success",
                                    });
                                }
                            }
                        })
                        .catch(() => {
                            setPushConfig({ s: "failed" });
                            if (!silent) {
                                toast({
                                    title: <Trans>Error while enabling Push Notification</Trans>,
                                    description: (
                                        <Trans>Couldn't store the device information, please try again.</Trans>
                                    ),
                                    status: "error",
                                });
                            }
                        });
                }
            })
            .catch((err) => {
                console.log(`error getting permission::${err.message}`);
                if (!silent) {
                    toast({
                        title: <Trans>Error while enabling Push Notification</Trans>,
                        description: (
                            <Box>
                                <Text fontSize="sm">
                                    {err.message ?? <Trans>Something went wrong</Trans>}{" "}
                                    <Link
                                        href="https://support.google.com/chrome/answer/3220216"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        fontSize="sm"
                                        isExternal
                                    >
                                        <Trans>Need help?</Trans> <ExternalLinkIcon mx="2px" />
                                    </Link>
                                </Text>
                            </Box>
                        ),
                        status: "error",
                    });
                }
                setPushConfig({ s: "failed" });
            });
    };

    useEffect(() => {
        if (!instanceId || !accountId || !userId) return;
        if (pushConfig?.s === "success" || pushConfig?.s === "disabled") {
            setReady(true);
            return;
        }

        getJSON(`/api/account/${accountId}/devices/instances/${instanceId}`)
            .then((d: Device) => {
                if (!d?.token || !(d?.isActive ?? true)) {
                    // throw new Error("Device not found");
                    getPushToken({ silent: true });
                    return;
                }
                setPushConfig({ i: instanceId, u: userId, a: accountId, t: d.token, s: "success" });
            })
            .catch((err) => {
                captureException(err);
            })
            .then(() => {
                setReady(true);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instanceId, accountId, userId, isPushNotificationSupported]);

    const toggleNotification = async () => {
        if (isLoading) return false;
        setIsLoading(true);
        const isEnableNotification = isReady && (pushConfig?.s === "unknown" || pushConfig?.s === "disabled");
        const isDisableNotification = isReady && pushConfig?.s === "success";

        if (!(isEnableNotification || isDisableNotification)) {
            reset();
            return;
        } else if (isEnableNotification) {
            await getPushToken({});
            setIsLoading(false);
            return;
        } else if (isDisableNotification) {
            await disableNotification();
            setIsLoading(false);
            return;
        }
    };

    useEffect(() => {
        if (!isPushNotificationSupported || pushConfig?.s === "disabled") return;

        Notification.requestPermission().then(function (permission) {
            if (permission === "denied") {
                setPushConfig({ s: "disabled" });
                return;
            }
        });

        if (Notification.permission === "denied") return;

        if (Notification.permission === "granted" && pushConfig?.s === "unknown") {
            getPushToken({ silent: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPushNotificationSupported]);

    return (
        <Box {...props} py={1} px={0} w="full">
            {!isReady ? (
                <Button isDisabled isLoading={isLoading} w="full" variant="link">
                    <Trans>Checking Notification Status</Trans>
                </Button>
            ) : (
                <HStack w="full">
                    <HStack spacing={2} w="full">
                        <VStack align="flex-start" w="full" spacing={0}>
                            <Flex w="full" justifyContent="space-between">
                                <HStack w="full" spacing={2}>
                                    <Box color={"gray.600"}>
                                        <Icon as={IoMdNotificationsOutline} fontSize="20px" color="gray.700" />
                                    </Box>
                                    <Text fontSize="sm" color={"gray.600"}>
                                        <Trans>Push Notification</Trans>
                                    </Text>
                                </HStack>
                                <Spacer />
                                <Box>
                                    {isLoading && pushConfig.s !== "unknown" ? (
                                        <Spinner size="xs" color="blue.500" />
                                    ) : (
                                        <Switch
                                            id="notification"
                                            size="sm"
                                            isChecked={
                                                isReady &&
                                                pushConfig?.s === "success" &&
                                                Notification.permission === "granted"
                                            }
                                            onChange={toggleNotification}
                                            isDisabled={!isPushNotificationSupported}
                                        />
                                    )}
                                </Box>
                            </Flex>
                            {!isPushNotificationSupported ? (
                                <Text fontSize="12px" fontWeight="500" textColor="red.500">
                                    <Trans>Your browser does not support push notification</Trans>
                                </Text>
                            ) : (
                                <>
                                    {((isReady && pushConfig?.s === "failed") ||
                                        Notification.permission === "denied") && (
                                        <HStack w="full" spacing={2} p={1}>
                                            <Button
                                                onClick={() => getPushToken({})}
                                                // w="full"
                                                variant="link"
                                                textColor="blue.500"
                                                fontWeight="100"
                                                fontSize="xs"
                                            >
                                                <Trans>Failed please retry?</Trans>
                                            </Button>
                                            <Divider orientation="vertical" color="gray.400" h="1rem" />
                                            <Link
                                                href="https://support.google.com/chrome/answer/3220216"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                fontSize="xs"
                                                isExternal
                                                minW="max-content"
                                            >
                                                <Trans>Need Help?</Trans> <ExternalLinkIcon mx="2px" />
                                            </Link>
                                        </HStack>
                                    )}
                                </>
                            )}
                        </VStack>
                    </HStack>
                </HStack>
            )}
        </Box>
    );
};

export default PushNotificationLink;
