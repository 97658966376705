import { createStandaloneToast, UseToastOptions } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { TemplateMessageFormValues } from "app/screens/Account/WATemplatesV2/SendWATemplates/EditTemplateVariables";
import {
    deSanitizeBodyValuesToFormValues,
    deSanitizeWAITemplateToEditForm,
    sanitizeFormValuesToWATemplateValues,
    templateEditFormSchema,
} from "app/screens/Account/WATemplatesV2/SendWATemplates/EditTemplateVariables/helpers";
import { LabelValue, WAITemplateMessage, WATemplateValues, YupSchema } from "app/types";
import { FilterType } from "app/types/filter";
import { Sequence, SequenceCreatableFields, SequenceStatus, SequenceStep } from "app/types/sequence";
import * as yup from "yup";
import { GlobalTimePreference, SequenceForm, SequenceStepForm } from "./CreateSequence";

export const getSequenceActionStatus = (
    status: SequenceStatus
): { title: string; status: UseToastOptions["status"] } | undefined => {
    if (status === "PUBLISHED") {
        return { title: t`Sequence Published successfully`, status: "success" };
    }
    if (status === "DRAFT") {
        return { title: t`Sequence Saved`, status: "info" };
    }
    if (status === "DELETED") {
        return { title: t`Sequence Deleted successfully`, status: "success" };
    }
    if (status === "PAUSED") {
        return { title: t`Sequence Deleted`, status: "info" };
    }
};

const BroadCastSchema = yup.object().shape<YupSchema<TemplateMessageFormValues>>({
    ...templateEditFormSchema(),
});

const getBaseSchema = () => {
    return {
        name: yup
            .string()
            .trim()
            .required(t`Please enter sequence name`)
            .max(32, t`Sequence name cannot be more than 32 characters`),
        entity: yup.string().required(t`Please enter sequence entity`),
        channelId: yup.string().required(t`Please select channel`),
        actionType: yup.string().required(t`Please select action`),
        subscriptionCondition: yup.mixed().when("actionType", {
            is: "CREATE",
            otherwise: yup.object().shape({
                rules: yup
                    .array()
                    .required(t`Condition is required for update trigger`)
                    .min(1, t`Condition is required for update trigger`),
            }),
        }),
        globalTimePreference: yup.object().shape({
            from: yup.string().when("timePreference.value", {
                is: "time_range",
                then: yup.string().required(t`From time is required`),
            }),
            to: yup.string().when("timePreference.value", {
                is: "time_range",
                then: yup.string().required(t`To time is required`),
            }),
            days: yup
                .array()
                .nullable()
                .min(1, t`Minimum one day must be selected`),
        }),
    };
};

// interface GlobalTimePreference extends Omit<TriggerTimePreference, "isActive"> {
//     timePreference?: "any_time" | "time_range";
//     dayPreference?: "all_day" | "select_day";
// }

const MAX_DAYS = 60;
export const MAX_SEQUENCE_STEPS = 10;

const getSequenceStepSchema = () => {
    return yup.object().shape({
        templateId: yup.string().required(t`Template is required`),
        delay: yup.object().shape({
            unit: yup.string().oneOf(["minute", "hour", "day"]),
            value: yup.number().when("unit", {
                is: "minute",
                then: yup
                    .number()
                    .typeError(t`Delay is required`)
                    .max(10080, t`Maximum value must be 10080 minute`)
                    .min(1, t`Minimum value must be 1 minute`),
                otherwise: yup.number().when("unit", {
                    is: "hour",
                    then: yup
                        .number()
                        .typeError(t`Delay is required`)
                        .max(168, t`Maximum value must be 168 hour`)
                        .min(1, t`Minimum value must be 1 hour`),
                    otherwise: yup
                        .number()
                        .typeError(t`Delay is required`)
                        .max(MAX_DAYS, `Maximum value must be ${MAX_DAYS} day`) //LANGFIX
                        .min(1, t`Minimum value must be 1 day`),
                }),
            }),
        }),
        triggerTimePreference: yup.object().shape({
            from: yup.string().when("timePreference.value", {
                is: "time_range",
                then: yup.string().required(t`From time is required`),
            }),
            to: yup.string().when("timePreference.value", {
                is: "time_range",
                then: yup.string().required(t`To time is required`),
            }),
            days: yup
                .array()
                .nullable()
                .min(1, t`Minimum one day must be selected`),
        }),
        templateValues: BroadCastSchema,
    });
};

export const getCreateSequenceSchema = () => {
    return yup.object().shape(getBaseSchema());
};

export const getUpdateSequenceSchema = () => {
    return yup.object().shape({ ...getBaseSchema(), steps: yup.array().of(getSequenceStepSchema()) });
};
const emptyRules: FilterType = {
    condition: "and",
    rules: [],
};

export const defaultSequenceStep: SequenceStepForm = {
    delay: {
        type: "LATER",
        unit: "minute",
        value: 1,
        isActive: false,
    },
    name: "",
    templateId: "",
    templateValues: {},
    triggerTimePreference: {
        timePreference: { label: "Any time", value: "any_time" },
        dayPreference: "all_day",
        days: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
    },
    isActive: false,
};

export const defaultValue: SequenceForm = {
    name: "",
    channelId: "",
    entity: "CONTACTS",
    type: "enrollment_date",
    actionType: "CREATE",
    shouldRunOnce: true,
    subscriptionCondition: emptyRules,
    failGoalCondition: emptyRules,
    successGoalCondition: emptyRules,
    steps: [defaultSequenceStep],
    globalTimePreference: {
        timePreference: { label: "Any time", value: "any_time" },
        dayPreference: "all_day",
        days: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
    },
    isActive: false,
    status: "DRAFT",
};

// export const sanitizePreference = (data: GlobalTimePreference): TriggerTimePreference => {
//     const { dayPreference, timePreference, from, to, ...restPreference } = data;
//     if (timePreference === "any_time") return restPreference;
//     return {
//         ...restPreference,
//         from,
//         to,
//     };
// };
// export const deSanitizePreference = (data: TriggerTimePreference): GlobalTimePreference => {
//     const { from, to, days } = data;
//     const timePreference = from && to ? "time_range" : "any_time";
//     const dayPreference = days?.length === 7 ? "all_day" : "select_day";
//     return { ...data, timePreference, dayPreference };
// };

const getTemplateValues = (
    templateValues: WATemplateValues,
    template: WAITemplateMessage | undefined
): TemplateMessageFormValues => {
    if (!template) return {};
    const { formValues } = deSanitizeWAITemplateToEditForm({ template });

    if (Boolean(Object.keys(templateValues).length)) {
        const { bodyValues, headerValues, cardValues, ...rest } = templateValues;

        return {
            ...rest,
            ...(!headerValues?.mediaUrl && formValues.headerValues?.mediaUrl
                ? {
                      headerValues: {
                          mediaName: formValues.headerValues?.mediaName,
                          mediaUrl: formValues.headerValues?.mediaUrl,
                      },
                  }
                : { headerValues }),
            bodyValues: deSanitizeBodyValuesToFormValues(bodyValues),
            ...(cardValues
                ? {
                      cardValues: cardValues.map((card, index) => ({
                          ...card,
                          bodyValues: deSanitizeBodyValuesToFormValues(card.bodyValues),
                      })),
                  }
                : {}),
        };
    }

    return formValues;
};

const getOptions: () => LabelValue<GlobalTimePreference["timePreference"]>[] = () => {
    return [
        { label: t`Time range`, value: "time_range" },
        { label: t`Any time`, value: "any_time" },
    ];
};

const sequenceStepToSequenceStepForm = (step: SequenceStep): SequenceStepForm => {
    const { id, template, templateValues, ...rest } = step;
    const options = getOptions();
    return {
        ...rest,
        template,
        templateValues: getTemplateValues(templateValues, template),
        isActive: rest.triggerTimePreference.isActive,
        triggerTimePreference: {
            ...rest.triggerTimePreference,
            timePreference: options.find((a) => a.value === rest.triggerTimePreference.timePreference) ?? options[1],
        },
    };
};

const sequenceStepFormToSequenceStep = (step: SequenceStepForm): SequenceStep => {
    const { templateValues, template, isActive, triggerTimePreference, _id, ...rest } = step;

    return {
        ...rest,
        templateValues: sanitizeFormValuesToWATemplateValues(templateValues) as WATemplateValues,
        template: template as WAITemplateMessage,
        triggerTimePreference: {
            ...triggerTimePreference,
            timePreference: triggerTimePreference.timePreference?.value,
            isActive,
        },
    };
};

export const sequenceToSequenceForm = (sequenceData: Sequence): SequenceForm => {
    const modifiedSteps: SequenceStepForm[] = sequenceData.steps.map(sequenceStepToSequenceStepForm);
    const options = getOptions();
    return {
        ...sequenceData,
        type: "enrollment_date",
        globalTimePreference: {
            ...sequenceData.globalTimePreference,
            timePreference: options.find((a) => a.value === sequenceData.globalTimePreference.timePreference),
        },
        steps: modifiedSteps.length ? modifiedSteps : [defaultSequenceStep],
    };
};
export const sequenceFormToSequence = (sequenceData: SequenceForm): SequenceCreatableFields => {
    const modifiedSteps: SequenceStep[] = sequenceData.steps.map(sequenceStepFormToSequenceStep);

    if (sequenceData.globalTimePreference.timePreference?.value === "any_time") {
        delete sequenceData.globalTimePreference.from;
        delete sequenceData.globalTimePreference.to;
    }

    return {
        ...sequenceData,
        globalTimePreference: {
            ...sequenceData.globalTimePreference,
            timePreference: sequenceData.globalTimePreference.timePreference?.value,
        },
        steps: modifiedSteps.length ? modifiedSteps : [sequenceStepFormToSequenceStep(defaultSequenceStep)],
    };
};

export const scrollInToView = (id: string): void => {
    const targetElement = document.getElementById(id);
    targetElement?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
};

export const sequenceValidationErrorToast = (): void => {
    const { toast } = createStandaloneToast();

    toast({
        description: t`Looks like you have missed configuring something in flow, Please fix and try again!`,
        status: "error",
        duration: 9000,
        isClosable: true,
    });
};
