// import "./wdyr";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
// The styles for prism need to be loaded once
import "prismjs/themes/prism-okaidia.css";
import "./index.css";
import "focus-visible/dist/focus-visible";
import { NODE_ENV, SENTRY_DSN_ENV } from "environment";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import App from "app/App";
import AppProvider from "AppProvider";

if (NODE_ENV !== "development") {
    Sentry.init({
        environment: NODE_ENV,
        dsn: SENTRY_DSN_ENV,
        autoSessionTracking: true,
        integrations: [
            new Integrations.BrowserTracing(),
            new Sentry.Replay({ blockAllMedia: true, maskAllInputs: false, maskAllText: false }),
        ],
        tracesSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
    });
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <AppProvider>
        <App />
    </AppProvider>
);

const updateServiceWorker = (registration: ServiceWorkerRegistration) => {
    const registrationWaiting = registration.waiting;

    if (registrationWaiting) {
        registrationWaiting.postMessage({ type: "SKIP_WAITING" });
        registrationWaiting.addEventListener("statechange", (e) => {
            if (e && (e?.target as ServiceWorker)?.state === "activated") {
                window.location.reload();
            }
        });
    }
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register({
    onUpdate: updateServiceWorker,
});
