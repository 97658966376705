import {
    Button,
    ButtonGroup,
    HStack,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    UseDisclosureReturn,
    VStack,
} from "@chakra-ui/react";
import React from "react";
import nudgeBG from "assets/nudgeBG.png";
import { windowOpenNewTab } from "../utils/common";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { useGBMediaQuery } from "app/hooks/useGBMediaQuery";
import { GROUP_DEMO_URL_ENV } from "environment";

interface NudgeModalProps extends UseDisclosureReturn {
    extendNudgeExpire: (option: number) => void;
}

const NudgeModal: React.FC<NudgeModalProps> = ({ isOpen, onClose, extendNudgeExpire }) => {
    const { isLessThan1280 } = useGBMediaQuery();
    const onDemoBook = () => {
        trackEvent({ event: "NUDGE_SNF_CONNECT" });
        windowOpenNewTab(GROUP_DEMO_URL_ENV);
        extendNudgeExpire(8760);
    };
    if (isLessThan1280) return null;
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
            <ModalOverlay />
            <ModalContent h="xs" w="full" borderRadius="lg">
                <ModalCloseButton
                    textColor="white"
                    onClick={() => {
                        trackEvent({ event: "NUDGE_SNF_CLOSE" });
                    }}
                />
                <ModalBody bgColor="blue.400" borderRadius="lg" p="0">
                    <HStack w="full" h="full">
                        <VStack h="full" justify="center" align="start" spacing="5" p="5">
                            <Text fontSize="2xl" textColor="white" fontWeight="bold">
                                Wanna talk to an expert before getting started?
                            </Text>
                            <ButtonGroup>
                                <Button bgColor="white" textColor="blue.400" variant="solid" onClick={onDemoBook}>
                                    Book demo
                                </Button>
                                <Button
                                    colorScheme="whiteAlpha"
                                    variant="outline"
                                    onClick={() => {
                                        trackEvent({ event: "NUDGE_SNF_CLOSE" });
                                        onClose();
                                    }}
                                >
                                    No thanks
                                </Button>
                            </ButtonGroup>
                        </VStack>
                        <VStack h="full" justify="end" w="80">
                            <Image src={nudgeBG} boxSize="60" />
                        </VStack>
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default NudgeModal;
