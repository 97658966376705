import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useAccountMasterHook } from "app/hooks/useAccountMasterHook";
import { useCheckAbility } from "app/hooks/useCheckAbility";
import { useRestrictFeature } from "app/hooks/useRestrictFeature";
import { openSupportTicketWidget } from "app/screens/Widget/utils";
import React from "react";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { BiBot, BiChart } from "react-icons/bi";
import { CgPlug, CgShoppingCart } from "react-icons/cg";
import { CiViewList } from "react-icons/ci";
import { HiOutlineChat, HiOutlineHome, HiOutlineSpeakerphone } from "react-icons/hi";
import { MdOutlineHelp } from "react-icons/md";
import { RiContactsBook2Line, RiSettings4Line } from "react-icons/ri";
import { TiFlowChildren } from "react-icons/ti";
import { NavigationAccordionItemProps } from "../components/NavigationAccordionItem";
import { AbilityActionSubject, NavigationMenuList } from "../types";

const accountReadActionSubject: AbilityActionSubject = {
    action: "read",
    subject: "Account",
};

type NavItemProps = Record<
    NavigationMenuList,
    Pick<NavigationAccordionItemProps, "mainNav"> & Partial<Pick<NavigationAccordionItemProps, "subNav">>
>;
interface UseNavigationListProps {
    navigationItems: NavItemProps;
}
export const useNavigationList = (): UseNavigationListProps => {
    const { _ } = useLingui();
    const { isSandboxChannel, isNewAccount, isFreeTrial } = useAccountMasterHook();
    const { canIReadWebhook } = useCheckAbility();
    const {
        data: { restrictPage: restrictIPConfigPage },
    } = useRestrictFeature("accounts_ip_restriction", "restrict");
    const {
        data: { pageAccess: accessPremiumSupport },
    } = useRestrictFeature("accounts_support", "restrict");

    const hideRaiseTicket = isSandboxChannel || isNewAccount;

    const chatWithUsURL =
        isFreeTrial || isSandboxChannel || isNewAccount
            ? "https://wame.pro/gbx-sales"
            : accessPremiumSupport
              ? "https://wa.me/917825810111"
              : "https://wame.pro/supportchannel";

    const navigationItems = React.useMemo((): NavItemProps => {
        return {
            "get-started": {
                mainNav: {
                    title: _(msg`Home`),
                    icon: HiOutlineHome,
                    to: "/getting-started",
                    eventName: "LNAV_HOME",
                },
            },
            conversations: {
                mainNav: {
                    title: _(msg`Conversations`),
                    icon: HiOutlineChat,
                    to: "/conversations",
                    eventName: "LNAV_CONV",
                    closeNavigationOnItemSelect: true,
                },
            },
            contacts: {
                mainNav: {
                    title: _(msg`Contacts`),
                    icon: RiContactsBook2Line,
                    to: "/contacts",
                    eventName: "LNAV_CONT_CONT",
                },
            },
            bots: {
                mainNav: { title: _(msg`Bots`), icon: BiBot, to: "/bots", eventName: "LNAV_BOT_MYBO" },
            },
            broadcast: {
                mainNav: {
                    title: _(msg`Broadcast`),
                    icon: HiOutlineSpeakerphone,
                    to: "/broadcast",
                    eventName: "LNAV_BROD",
                },
            },
            sequence: {
                mainNav: {
                    title: _(msg`Sequence`),
                    icon: TiFlowChildren,
                    to: "/sequence",
                    badge: "new",
                    iconProps: { fontSize: "18px" },
                    eventName: "LNAV_SEQU",
                },
            },
            commerce: {
                mainNav: { title: _(msg`Commerce`), icon: CgShoppingCart },
                subNav: [
                    {
                        title: _(msg`Catalogues`),
                        to: "/facebook-catalogs",
                        eventName: "LNAV_COMM_CATA",
                        ...accountReadActionSubject,
                        action: "create",
                        subject: "Catalog",
                    },

                    {
                        title: _(msg`Orders`),
                        to: "/whatsapp-order",
                        action: "read",
                        subject: "WhatsappOrders",
                        eventName: "LNAV_COMM_ORDE",
                    },
                    {
                        title: _(msg`Payments`),
                        to: "/payments",
                        badge: "new",
                        eventName: "LNAV_COMM_PAY",
                        action: "read",
                        subject: "Payments",
                    },
                ],
            },
            flows: {
                mainNav: {
                    title: _(msg`Flows`),
                    to: "/whatsapp-flows",
                    icon: CiViewList,
                    iconProps: { fontSize: "20px" },
                    eventName: "LNAV_WHAT_FLOWS",
                    badge: "new",
                },
            },
            integration: {
                mainNav: {
                    title: _(msg`Integration`),
                    icon: CgPlug,
                    to: "/integration",
                    eventName: "LNAV_SETT_INTE",
                    iconProps: { fontSize: "20px" },
                    action: "read",
                    subject: "Integration",
                },
            },

            settings: {
                mainNav: {
                    title: _(msg`Settings`),
                    icon: RiSettings4Line,
                },
                subNav: [
                    {
                        title: _(msg`WhatsApp templates`),
                        to: "/whatsapp-template-messagesv2",
                        eventName: "LNAV_SETT_WATE",
                        action: "create",
                        subject: "WhatsappTemplate",
                    },
                    {
                        title: _(msg`CTWA`),
                        to: "/ctwa-leads",
                        eventName: "LNAV_C2WA",
                        action: "read",
                        subject: "CTWA",
                    },
                    {
                        title: _(msg`Canned response`),
                        to: "/cannedresponse",
                        action: "create",
                        subject: "CannedResponse",
                        eventName: "LNAV_SETT_CANE",
                    },
                    {
                        title: _(msg`Message tracker`),
                        to: "/message-tracking",
                        eventName: "LNAV_SETT_MESS",
                        action: "read",
                        subject: "MessageTracker",
                    },

                    { type: "divider" },

                    {
                        title: _(msg`Field collection`),
                        to: "/fields",
                        eventName: "LNAV_SETT_FIEL",
                        action: "create",
                        subject: "Fields",
                    },
                    {
                        title: _(msg`Tags`),
                        to: "/tags",
                        eventName: "LNAV_SETT_TAGS",
                        action: "create",
                        subject: "Tags",
                    },

                    { type: "divider" },

                    {
                        title: _(msg`WhatsApp Channel`),
                        to: "/channels/whatsapp",
                        action: "update",
                        subject: "Channel",
                        eventName: "LNAV_SETT_WACH",
                    },
                    {
                        title: _(msg`Web-Chat Channel`),
                        to: "/channels/web",
                        action: "update",
                        subject: "Channel",
                        eventName: "LNAV_SETT_WCCH",
                    },

                    { type: "divider" },

                    {
                        title: _(msg`Webhooks`),
                        to: "/webhook",
                        hide: !canIReadWebhook,
                        eventName: "LNAV_SETT_WEBH",
                    },
                    {
                        title: _(msg`API Keys`),
                        to: "/apikey",
                        action: "read",
                        subject: "APIKey",
                        eventName: "LNAV_SETT_APIK",
                    },
                    {
                        title: _(msg`Activity log`),
                        to: "/activities",
                        action: "read",
                        subject: "ActivityLog",
                        eventName: "LNAV_SETT_ACTV",
                    },
                    {
                        title: _(msg`Allowed IPs`),
                        to: "/allowed-ips",
                        action: "update",
                        subject: "Account",
                        eventName: "LNAV_SETT_ACTV",
                        hide: restrictIPConfigPage,
                    },
                    /* {
                        title: "Knowledge Base",
                        to: "/knowledge-base",
                        action: "update",
                        subject: "Account",
                        eventName: "LNAV_SETT_ACTV",
                    }, */
                ],
            },
            analytics: {
                mainNav: { title: _(msg`Analytics`), icon: BiChart },
                subNav: [
                    {
                        title: _(msg`Dashboard`),
                        to: "/dashboard",
                        action: "read",
                        subject: "Analytics",
                        eventName: "LNAV_ANAL_DASH",
                    },
                    {
                        title: _(msg`WA Failed message`),
                        to: "/analytics/report/failed-message",
                        action: "read",
                        subject: "Analytics",
                        eventName: "LNAV_ANAL_WAFAI",
                    },
                    {
                        title: _(msg`WA Notification message`),
                        to: "/analytics/report/notification-message",
                        action: "read",
                        subject: "Analytics",
                        eventName: "LNAV_ANAL_NOTI",
                    },
                    {
                        title: _(msg`Conversation report`),
                        to: "/conversation-report",
                        action: "readMany",
                        subject: "Analytics",
                        eventName: "LNAV_ANAL_CONV",
                    },
                ],
            },
            help: {
                mainNav: {
                    title: _(msg`Help`),
                    icon: MdOutlineHelp,
                    buttonProps: {
                        color: "cyan.400",
                        fontWeight: "bold",
                    },
                    iconProps: {
                        color: "cyan.400",
                        fontWeight: "bold",
                        fontSize: "18px",
                    },
                },
                subNav: [
                    { title: _(msg`Chat with us`), href: chatWithUsURL, eventName: "LNAV_HELP_CHAT" },
                    {
                        title: _(msg`Raise a ticket`),
                        onClick: openSupportTicketWidget,
                        // href: "https://help.gallabox.com/support/tickets/new",
                        eventName: "LNAV_HELP_RAIS",
                        hide: hideRaiseTicket,
                    },
                    { type: "divider" },
                    { title: _(msg`Help Docs`), href: "https://docs.gallabox.com/", eventName: "LNAV_HELP_HELP" },
                    {
                        title: _(msg`FAQs`),
                        href: "https://docs.gallabox.com/frequently-asked-questions",
                        eventName: "LNAV_HELP_FAQ",
                    },
                ],
            },
            "whats-new": {
                mainNav: {
                    title: _(msg`What’s New`),
                    icon: AiOutlineThunderbolt,
                    buttonProps: { "data-canny-changelog": true },
                    eventName: "LNAV_WHATSNEW",
                },
            },
        };
    }, [_, canIReadWebhook, restrictIPConfigPage, chatWithUsURL, hideRaiseTicket]);

    return { navigationItems };
};
