import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import AlertDialog, { AlertDialogProps } from "app/components/AlertDialog/AlertDialogNoTrigger";
import React from "react";

type ExitAlertProps = Omit<AlertDialogProps, "title" | "body" | "primaryButtonText" | "secondaryButtonText"> & {
    bodyText?: string | JSX.Element;
};

const ExitAlert: React.FC<ExitAlertProps> = (props) => {
    const { _ } = useLingui();
    return (
        <AlertDialog
            title={<Trans>Exit Import</Trans>}
            body={props.bodyText ?? <Trans>Are you sure? If you exit, you will lose all the changes you made.</Trans>}
            primaryButtonText={_(msg`Confirm`)}
            secondaryButtonText={_(msg`Cancel`)}
            primaryButtonProps={{ colorScheme: "brand" }}
            secondaryButtonProps={{ variant: "ghost" }}
            {...props}
        />
    );
};

export default ExitAlert;
