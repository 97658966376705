import { Image, Text, VStack } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import NoImportContact from "assets/import-contact/no-import-contact.svg";
import React from "react";
import NewImportButton from "./NewImportButton";

const ImportContactEmptyPage: React.FC = () => {
    return (
        <VStack w="full" mx="auto" h="95%" justify="center" spacing={4}>
            <Image src={NoImportContact} />
            <VStack spacing={1}>
                <Text color="gray.700" fontSize="lg" fontWeight="bold">
                    <Trans>No Import Contacts</Trans>
                </Text>
                <Text fontSize="sm" color="gray.500" textAlign="center">
                    <Trans>
                        Import your customer base into Gallabox
                        <br />
                        to kick-start the conversations.
                    </Trans>
                </Text>
            </VStack>
            <NewImportButton />
        </VStack>
    );
};

export default ImportContactEmptyPage;
