import { Icon, Text, useDisclosure } from "@chakra-ui/react";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { AlertBannerPropsWithVisibility } from "app/components/AlertBanner/MultiAlert";
import { supportDemoURL } from "app/contants";
import { useAccountOverUsage } from "app/screens/Account/AccountOverUsage/useAccountOverUsage";
import NewFreeTrialModal from "app/screens/Onboarding/New/NewFreeTrialModal";
import dayjs from "dayjs";
import React from "react";
import { HiInformationCircle } from "react-icons/hi";
import { LuCalendarHeart } from "react-icons/lu";
import { RiAlertFill, RiUser3Line } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { trackEvent } from "./productAnalytics/tracker";
import { useAccountMasterHook } from "./useAccountMasterHook";
import { useRedirectToDialog360Page } from "./useRedirectToDialog360Page";

const SANDBOX_DAYS = 3;
const TRIAL_DAYS = 6;

interface ShowTopAlertBannerProps {
    multiAlertProps: AlertBannerPropsWithVisibility[];
}

const alertInfoIcon = <Icon as={HiInformationCircle} color="gray.500" />;
const alertWarningIcon = <Icon as={RiAlertFill} color="red.500" />;

export const useShowTopAlertBanner = (): ShowTopAlertBannerProps => {
    const { _ } = useLingui();
    const history = useHistory();

    const {
        isSuspended,
        isSandboxChannel,
        isNewAccount,
        isFreeTrial,
        hasOpenInvoice,
        needActivation,
        sandboxExpireDate,
        freeTrialExpireDate,
        sandboxLifeSpan,
    } = useAccountMasterHook();
    const { isAccountOverUsed } = useAccountOverUsage();
    const { redirectToDialog360Page } = useRedirectToDialog360Page();
    // free trail details

    const freeTrailExpireContent = React.useMemo(() => {
        if (!freeTrialExpireDate) return "";

        const daysLeft = dayjs(freeTrialExpireDate).utc().endOf("day").diff(dayjs().utc().startOf("day"), "day");
        if (daysLeft > TRIAL_DAYS) return "";
        if (daysLeft > 0)
            return (
                <Text fontSize="xs" color="gray.500">
                    Your Gallabox free trial with
                    <Text as="span" fontWeight="bold">
                        GROWTH
                    </Text>{" "}
                    plan will expire in {daysLeft}
                    {daysLeft > 1 ? "days" : "day"}
                </Text>
            );
        if (daysLeft === 0) return _(msg`You’re on the Gallabox Pro Plan. Your free trial ends today!`);
        if (daysLeft > -3) return _(msg`Your Gallabox free trial is expired!`);
        return _(msg`⚠️ Payment required!`);
    }, [_, freeTrialExpireDate]);

    // sandbox details
    const sandboxAlertContent = React.useMemo(() => {
        if (!sandboxExpireDate) return "";
        const daysLeft = dayjs(sandboxExpireDate).utc().endOf("day").diff(dayjs().utc().startOf("day"), "day");
        if (daysLeft > (sandboxLifeSpan ? sandboxLifeSpan + 1 : SANDBOX_DAYS)) return "";
        if (daysLeft > 0)
            return `You’re currently using Gallabox demo account which ends in ${daysLeft} ${
                daysLeft > 1 ? "days" : "day"
            }`;
        if (daysLeft === 0) return _(msg`You’re currently using Gallabox demo account which ends today!`);
        if (daysLeft > -3) return _(msg`Your Gallabox demo account is expired!`);
        return _(msg`⚠️ Action required!`);
    }, [_, sandboxExpireDate, sandboxLifeSpan]);

    const newFreeTrialModalDisclosure = useDisclosure();

    const onGetYourOwnNumber = () => {
        history.push("/channels/whatsapp/meta-tech-partner");
        trackEvent({ event: "ACCOUNT Connect Number", properties: { type: "Top strip" } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const multiAlertProps = React.useMemo(() => {
        const alerts: AlertBannerPropsWithVisibility[] = [
            {
                isShow: isFreeTrial,
                content: {
                    text: freeTrailExpireContent,
                },
                leftIcon: alertInfoIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: _(msg`I’m ready to upgrade`),
                        buttonProps: {
                            color: "gray.600",
                            fontSize: "xs",
                            fontWeight: "medium",
                            textDecor: "underline",
                            variant: "unstyled",
                            colorScheme: undefined,
                            _hover: {
                                bg: "yellow.200",
                            },
                            onClick: () => {
                                history.push("/plan-and-subscription?tab=1");
                            },
                        },
                    },

                    secondaryButton: {
                        buttonText: _(msg`Book a free consultation`),
                        buttonProps: {
                            leftIcon: <Icon as={LuCalendarHeart} />,
                            onClick: () => {
                                trackEvent({
                                    event: "GS_BOOK_DEMO_TOP",
                                });
                            },
                            rightIcon: undefined,
                            ml: 4,
                        },
                        href: supportDemoURL,
                    },
                },
            },
            {
                isShow: isSandboxChannel,
                content: {
                    text: sandboxAlertContent,
                },
                leftIcon: alertInfoIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: _(msg`I’m ready to connect my number`),
                        buttonProps: {
                            color: "gray.600",
                            fontSize: "xs",
                            fontWeight: "medium",
                            textDecor: "underline",
                            variant: "unstyled",
                            colorScheme: undefined,
                            onClick: onGetYourOwnNumber,
                            _hover: {
                                bg: "yellow.200",
                            },
                        },
                    },

                    secondaryButton: {
                        buttonText: _(msg`Book a free consultation`),
                        buttonProps: {
                            leftIcon: <Icon as={LuCalendarHeart} />,
                            rightIcon: undefined,
                            ml: 4,
                            onClick: () => {
                                trackEvent({
                                    event: "GS_BOOK_DEMO",
                                    properties: { state: "Success", type: "Top strip" },
                                });
                            },
                        },
                        href: supportDemoURL,
                    },

                    canPermissionCheck: true,
                    additionalCmptRender: (
                        <NewFreeTrialModal
                            isOpen={newFreeTrialModalDisclosure.isOpen}
                            onClose={newFreeTrialModalDisclosure.onClose}
                        />
                    ),
                },
            },
            {
                isShow: isNewAccount,
                content: {
                    text: <Trans>Your are almost there, Get started with your own Whatsapp number</Trans>,
                },
                leftIcon: alertInfoIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: _(msg`Connect your number`),
                        buttonProps: {
                            onClick: onGetYourOwnNumber,
                        },
                    },
                    secondaryButton: {
                        buttonText: _(msg`Connect with our product expert`),
                        href: supportDemoURL,
                    },
                    canPermissionCheck: true,
                },
            },
            {
                isShow: needActivation,
                content: {
                    text: <Trans>Your channel is yet to be activated</Trans>,
                },
                leftIcon: alertInfoIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: _(msg`Activate now`),
                        buttonProps: {
                            onClick: () => {
                                redirectToDialog360Page();
                            },
                        },
                    },

                    canPermissionCheck: true,
                },
            },
            {
                isShow: isAccountOverUsed,
                content: {
                    text: <Trans>Your user limit is exceeded, Please add more users for your Subscription Plan.</Trans>,
                },
                leftIcon: <Icon as={RiUser3Line} color="gray.800" fontSize="sm" />,
                ctaButton: {
                    primaryButton: {
                        buttonText: _(msg`Add Users`),
                        buttonProps: {
                            onClick: () => history.push("/plan-and-subscription?tab=1"),
                        },
                    },

                    canPermissionCheck: true,
                },
            },
            {
                isShow: hasOpenInvoice,
                content: {
                    text: <Trans>You have an unpaid invoice</Trans>,
                },
                leftIcon: alertWarningIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: _(msg`Pay now`),
                        buttonProps: {
                            onClick: () => history.push("/plan-and-subscription#invoice"),
                        },
                    },
                },
            },
            {
                isShow: isSuspended,
                content: {
                    text: <Trans>Your Account is SuspendedYour Account is Suspended</Trans>,
                },
                leftIcon: alertWarningIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: _(msg`Pay now`),
                        buttonProps: {
                            onClick: () => history.push("/plan-and-subscription#invoice"),
                        },
                    },
                },
            },
            {
                isShow: true,
                content: {
                    text: (
                        <Text as="span">
                            <Trans>
                                <Text textColor="gray.900" fontWeight="bold" as="span">
                                    Effective November 1, 2024:{" "}
                                </Text>
                                Service conversations are free now!
                            </Trans>
                        </Text>
                    ),
                },
                isClosable: true,
            },
        ];

        return alerts.filter((ob) => ob.isShow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        freeTrailExpireContent,
        hasOpenInvoice,
        history,
        isAccountOverUsed,
        isFreeTrial,
        isNewAccount,
        isSandboxChannel,
        isSuspended,
        needActivation,
        newFreeTrialModalDisclosure.isOpen,
        redirectToDialog360Page,
        sandboxAlertContent,
    ]);

    return { multiAlertProps };
};
