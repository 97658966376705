import {
    AvatarBadge,
    Box,
    Button,
    Divider,
    HStack,
    Icon,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverTrigger,
    Spacer,
    Switch,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import InfoTooltip from "app/components/InfoTooltip";
import LoadingState from "app/components/LoadingState";
import ProfileCard from "app/components/ProfileCard";
import PushNotificationLink from "app/components/PushNotification/PushNotificationLink";
import { useUserAvailability } from "app/fetchHooks/user-availability";
import { useAccountMasterHook } from "app/hooks/useAccountMasterHook";
import { useCheckAbility } from "app/hooks/useCheckAbility";
import { AvailabilityType } from "app/types/common";
import { User } from "app/types/user";
import { useAccount } from "app/utils/react-helpers";
import "driver.js/dist/driver.min.css";
import { default as React, useEffect, useState } from "react";
import { BiDotsVerticalRounded, BiWalletAlt } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { FiUsers } from "react-icons/fi";
import { HiCheck } from "react-icons/hi";
import { IoCloseCircle } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import { RiLogoutCircleRLine, RiProfileLine, RiVipCrownLine } from "react-icons/ri";
import NavigationButton, { NavigationButtonProps } from "./components/NavigationButton";
import { useNavigationTrackEvent } from "./hooks/useNavigationTrackEvent";

const popoverNavBtnProps: Partial<NavigationButtonProps> = {
    size: "sm",
    fontWeight: "normal",
    fontSize: "2xs",
    rounded: "none",
    justifyContent: "start",
    bg: "transparent",
    w: "full",
    color: "gray.600",
    iconProps: { color: "gray.700" },
};

interface ProfilePopoverProps {
    user: User;
    isCollapsed: boolean;
}
const ProfilePopover: React.FC<ProfilePopoverProps> = (props) => {
    const { _ } = useLingui();
    const { user, isCollapsed } = props;

    const [isActive, setActive] = useState<boolean>(user?.accounts?.[0]?.availability === "Available");
    const [isAlertOpen, setAlertOpen] = useState(false);
    const account = useAccount();
    const { isSandboxChannel } = useAccountMasterHook();
    const { canIReadSubscription, canIReadWallet, canIReadUsersAndRole } = useCheckAbility();
    const trackLeftNavEvent = useNavigationTrackEvent({ isCollapsed });

    const { isOpen, onClose, onToggle } = useDisclosure();
    useEffect(() => {
        setActive(user?.accounts?.[0]?.availability === "Available");
    }, [user]);

    const onProfileItemClick = React.useCallback((event: string) => {
        onClose();
        trackLeftNavEvent(event);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSuccess = () => {
        setActive((prev) => !prev);
    };

    const onAlertClose = () => {
        setAlertOpen(false);
    };
    useEffect(() => {
        setAlertOpen(user?.accounts?.[0]?.availability === "Away");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { mutateAsync: updateUserAvailability, isLoading } = useUserAvailability({
        accountId: account?.id as string,
        userId: user?.id,
        onSuccess,
    });

    const onAvailabilityChange = React.useCallback(async (statusToUpdate: AvailabilityType) => {
        await updateUserAvailability({ availability: statusToUpdate });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const alertAvailability = React.useMemo(() => {
        return (
            <Popover isOpen={isAlertOpen} autoFocus={false} placement="bottom-start">
                {() => (
                    <>
                        {isAlertOpen ? (
                            <Box pos="fixed" bgColor={"blackAlpha.600"} top="0" bottom="0" left="-1" right="0" />
                        ) : null}
                        <PopoverTrigger>
                            <Box h={1} pos="absolute" bottom="50px" left="20px" />
                        </PopoverTrigger>
                        <PopoverContent zIndex="1000">
                            <PopoverArrow />
                            <PopoverBody>
                                <Text fontSize={"sm"} color="gray.700">
                                    <Trans>Did you forget to turn on your Availability status?</Trans>
                                </Text>
                            </PopoverBody>
                            <PopoverFooter border={"none"} pt="0" pb="4">
                                <HStack spacing={2}>
                                    <Button
                                        onClick={async () => {
                                            await onAvailabilityChange("Available");
                                            onAlertClose();
                                        }}
                                        isLoading={isLoading}
                                        borderRadius="md"
                                        colorScheme="brand"
                                        variant="solid"
                                        size="xs"
                                    >
                                        <Trans>Turn On Now</Trans>
                                    </Button>
                                    <Button onClick={onAlertClose} borderRadius="md" variant="outline" size="xs">
                                        <Trans>Not Now</Trans>
                                    </Button>
                                </HStack>
                            </PopoverFooter>
                        </PopoverContent>
                    </>
                )}
            </Popover>
        );
    }, [isAlertOpen, isLoading, onAvailabilityChange]);

    return (
        <>
            <Popover placement="top-start" isOpen={isOpen}>
                <PopoverTrigger>
                    <HStack
                        px="5"
                        py="1.5"
                        w="full"
                        borderTopWidth="1px"
                        borderTopColor="gray.200"
                        borderTopStyle="solid"
                        _hover={{ bg: "gray.100" }}
                        cursor="pointer"
                        onClick={onToggle}
                        spacing={0}
                        data-cy="navigation-profile-menu"
                    >
                        <ProfileCard
                            name={user?.name}
                            src={account?.logoUrl}
                            colorKey={account?.id}
                            value1={isCollapsed ? undefined : account?.name}
                            value2={isCollapsed ? undefined : user.name}
                            size={isCollapsed ? "xs" : "sm"}
                        >
                            {!isActive ? (
                                <AvatarBadge boxSize="4" bg="white">
                                    <Icon as={IoCloseCircle} w="4" h="4" />
                                </AvatarBadge>
                            ) : null}
                        </ProfileCard>
                        {!isCollapsed && <Icon as={BiDotsVerticalRounded} color="gray.800" />}
                    </HStack>
                </PopoverTrigger>

                {!isAlertOpen ? (
                    <PopoverContent rounded={"none"}>
                        <PopoverBody boxShadow="lg" p={0} onMouseLeave={onClose}>
                            <VStack w="full" justify="start" spacing={0} px="2" pb="2">
                                <Box px="2.5" py="3" w="full">
                                    <ProfileCard
                                        name={account?.name}
                                        src={account?.logoUrl}
                                        colorKey={account?.id}
                                        value1={account?.name}
                                        value2={account?.website}
                                        value2Type="link"
                                    />
                                </Box>
                                <Box
                                    w="full"
                                    onClick={() => {
                                        onProfileItemClick("LNAV_ACC_DETAILS");
                                    }}
                                >
                                    <NavigationButton
                                        title={_(msg`Account Details`)}
                                        icon={RiProfileLine}
                                        to="/account"
                                        {...popoverNavBtnProps}
                                    />
                                </Box>
                                {canIReadSubscription && !isSandboxChannel && (
                                    <Box
                                        w="full"
                                        onClick={() => {
                                            onProfileItemClick("LNAV_SUBS");
                                        }}
                                    >
                                        <NavigationButton
                                            title={_(msg`Subscription`)}
                                            icon={RiVipCrownLine}
                                            to="/plan-and-subscription"
                                            {...popoverNavBtnProps}
                                        />
                                    </Box>
                                )}

                                {canIReadWallet && !isSandboxChannel && (
                                    <Box
                                        w="full"
                                        onClick={() => {
                                            onProfileItemClick("LNAV_MSSG_CREDITS");
                                        }}
                                    >
                                        <NavigationButton
                                            title={_(msg`Message credits`)}
                                            icon={BiWalletAlt}
                                            to="/message-credits"
                                            {...popoverNavBtnProps}
                                        />
                                    </Box>
                                )}
                                {canIReadUsersAndRole && (
                                    <Box
                                        w="full"
                                        onClick={() => {
                                            onProfileItemClick("LNAV_USER_ROLES");
                                        }}
                                    >
                                        <NavigationButton
                                            title={_(msg`Users & roles`)}
                                            icon={FiUsers}
                                            to="/roles"
                                            {...popoverNavBtnProps}
                                        />
                                    </Box>
                                )}
                            </VStack>
                            <Divider />
                            <VStack w="full" justify="start" spacing={0} px="2" pb="2">
                                <Box px="2.5" py="3" w="full">
                                    <ProfileCard
                                        name={user.name}
                                        src={user.picture}
                                        colorKey={user?.id}
                                        value1={user.name}
                                        value2={user.email}
                                        badgeContent={user.accounts?.[0]?.role?.name}
                                    >
                                        {!isActive ? (
                                            <AvatarBadge boxSize="4" bg="white">
                                                <Icon as={IoCloseCircle} w="4" h="4" />
                                            </AvatarBadge>
                                        ) : null}
                                    </ProfileCard>
                                </Box>
                                <Box w="full" px="3">
                                    <PushNotificationLink />
                                </Box>
                                <HStack w="full" pr={3} align="start">
                                    <NavigationButton
                                        title={isActive ? _(msg`Active`) : _(msg`Inactive`)}
                                        icon={isActive ? HiCheck : MdOutlineCancel}
                                        {...popoverNavBtnProps}
                                        w="max-content"
                                        _hover={{ bg: "none" }}
                                        _active={{ bg: "none" }}
                                        pr="0"
                                    />
                                    <Box alignSelf="center">
                                        <InfoTooltip
                                            text={
                                                <Text>
                                                    <Trans>
                                                        You will not receive notifications and team members will not be
                                                        able to assign conversations to you. <br />
                                                        Note: This is a manual switch and can only be turned on by the
                                                        user.
                                                    </Trans>
                                                </Text>
                                            }
                                            placement="top"
                                            infoIconProps={{ color: "gray.500", fontSize: "md" }}
                                        />
                                    </Box>
                                    <Spacer />
                                    {isLoading ? (
                                        <LoadingState />
                                    ) : (
                                        <Switch
                                            size="sm"
                                            id="switch-action"
                                            onChange={() => onAvailabilityChange(isActive ? "Away" : "Available")}
                                            isChecked={isActive}
                                        />
                                    )}
                                </HStack>
                                <Box
                                    w="full"
                                    onClick={() => {
                                        onProfileItemClick("LNAV_PROFILE");
                                    }}
                                >
                                    <NavigationButton
                                        title={_(msg`Profile`)}
                                        icon={CgProfile}
                                        to="/profile"
                                        {...popoverNavBtnProps}
                                    />
                                </Box>

                                <Box
                                    w="full"
                                    onClick={() => {
                                        onProfileItemClick("LNAV_LOGOUT");
                                    }}
                                >
                                    <NavigationButton
                                        title={_(msg`Logout`)}
                                        icon={RiLogoutCircleRLine}
                                        {...popoverNavBtnProps}
                                        to="/logout"
                                        color="red.400"
                                        iconProps={{ color: "red.400" }}
                                    />
                                </Box>
                            </VStack>
                        </PopoverBody>
                    </PopoverContent>
                ) : null}
            </Popover>
            {alertAvailability}
        </>
    );
};

export default ProfilePopover;
