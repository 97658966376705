import React from "react";
import { useHistory } from "react-router-dom";
import Async, { Props as AsyncSelectProps } from "react-select/async";
import { CustomOption } from "./CustomOption";
import { getDefaultValue } from "./PopUpNavigator";
import { usePopNavigationSelectStyle } from "./styles";
import { Option } from "./types";
import { components } from "react-select";

interface IPopUpNavigationAsyncSelect extends AsyncSelectProps<Option, false> {
    onClose: () => void;
    placeholderValue?: string;
}

export const PopUpNavigationAsyncSelect: React.FC<IPopUpNavigationAsyncSelect> = (props) => {
    const history = useHistory();
    const { onClose, placeholderValue, ...otherProps } = props;
    const selectStyles = usePopNavigationSelectStyle();

    const onChange: AsyncSelectProps<Option, false>["onChange"] = (value) => {
        if (!value) return;
        history.push((value as unknown as Option).navigationLink);
        onClose();
    };

    return (
        <Async
            {...otherProps}
            placeholder={placeholderValue ?? getDefaultValue()["placeholderValue"]}
            styles={selectStyles}
            cacheOptions
            defaultOptions
            menuIsOpen
            onChange={onChange}
            components={{
                Option: CustomOption as typeof components.Option,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
            }}
        />
    );
};
