import { subject } from "@casl/ability";
import { AppAbility, Subject } from "app/config/abilities/ability";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { checkOS } from "app/utils/common";
import { getNavigationMenuItems } from "../utils";
import { PopUpNavigationProps } from "./types";

export const checkAndTogglePopUpNavigation = (
    e: KeyboardEvent,
    openPopUpNavigator: () => void,
    closePopUpNavigator: () => void,
    popUpNavigatorIsOpen: boolean
): void => {
    let openPopNavigation = false;
    const isEscape = e.key === "Escape" && popUpNavigatorIsOpen;
    const isMac = checkOS().includes("mac");
    if (isMac) {
        openPopNavigation = e?.metaKey && e?.key?.toLowerCase() === "k";
    } else {
        openPopNavigation = e?.ctrlKey && e?.key?.toLowerCase() === "k";
    }

    if (openPopNavigation) {
        e.preventDefault();
        openPopUpNavigator();
        trackEvent({ event: "LNAV_CTRL+K" });
    }

    if (isEscape) {
        e.preventDefault();
        closePopUpNavigator();
    }
};

export const getNavigationItemsByRolesAndPermissions = (
    ability: AppAbility,
    accountId: string
): PopUpNavigationProps[] | [] => {
    const navigationMenuItems = getNavigationMenuItems();
    const allowedNavigationMenu = navigationMenuItems.filter((menuItem) => {
        if (!menuItem.subject) return false;
        return ability.can("read", subject<Subject, any>(menuItem.subject, { accountId }));
    });
    const uniqueGroupMenuNames = Array.from(
        new Set(allowedNavigationMenu.map((menuItem) => menuItem.groupName))
    ).filter(Boolean);
    const groupedNavigationItems: PopUpNavigationProps[] = [];
    uniqueGroupMenuNames.forEach((groupMenuName) => {
        const groupedItems = allowedNavigationMenu?.filter((menuItem) => menuItem.groupName == groupMenuName);
        if (groupedItems.length && groupMenuName) {
            groupedNavigationItems.push({
                label: groupMenuName,
                options: groupedItems,
            });
        }
    });
    return groupedNavigationItems;
};
