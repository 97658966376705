import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    ButtonGroup,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { msg, t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FileComponent } from "app/components/Fields/FieldComponent";
import LearnMoreLink from "app/components/LearnMoreLink";
import TagMultiSelect, { TagSelectOption } from "app/components/Tags/TagMultiSelect";
import { marketingOptinDocURL } from "app/contants";
import { usePostCreateImport, useUpdateImport } from "app/fetchHooks/entityImport";
import FormControlWrapper from "app/gbComponents/Misc/FormControlWrapper";
import { LabelValue } from "app/types";
import { ImportContact } from "app/types/entity";
import { callFnsInSequence } from "app/utils/common";
import { Input, ReactSelectV2 } from "app/utils/formUtils";
import { useAccountId } from "app/utils/react-helpers";
import dayjs from "dayjs";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import AvoidCommonErrorInfo from "../../component/AvoidCommonErrorInfo";
import ExitAlert from "../../component/ExitAlert";
import ImportErrorInfo from "../../component/ImportErrorInfo";
import ImportInfoCompInfo from "../../component/ImportInfoComp";
import ReadyForMappingModal from "../../component/ReadyForMappingModal";
import { useGetEntityImportVersion } from "../../hooks/useGetEntityImportVersion";
import { useListenImportUpdates } from "../../hooks/useListenImportUpdates";
import { trackEvent } from "app/hooks/productAnalytics/tracker";

export const getMessageOptInOptions: () => LabelValue<boolean, string>[] = () => {
    return [
        {
            label: t`Yes`,
            value: true,
        },
        {
            label: t`No`,
            value: false,
        },
    ];
};

export const getImportTypeOptions: () => LabelValue<ImportContact["importType"]>[] = () => {
    return [
        {
            label: t`Add new contacts`,
            value: "CREATE",
        },
        {
            label: t`Add new & update existing contact`,
            value: "UPSERT",
        },
    ];
};

const ENTITY_IMPORT_MAX_FILE_SIZE = 15 * 1024 * 1024; //15mb;

export interface ImportNewContactFormType {
    fileId: string;
    file: { id: string; url?: string };
    name: string;
    importType: LabelValue<ImportContact["importType"]>;
    messageOptIn: LabelValue;
    tags: TagSelectOption[];
}

const getImportNewContactFormSchema = () => {
    return yup.object().shape({
        fileId: yup.string(),
        file: yup
            .mixed()
            .when("fileId", {
                is: (fileId: string) => Boolean(fileId),
                then: yup.object(),
                otherwise: yup
                    .object()
                    .nullable()
                    .shape({
                        url: yup.string().required(t`File is required`),
                    })
                    .required(t`File is required`),
            })
            .required(t`File is required`),
        name: yup.string().required(t`Import name is required`),
        importType: yup
            .object()
            .nullable()
            .shape({
                label: yup.string().required(t`Import type is required`),
                value: yup.string().required(t`import type is required`),
            }),
        messageOptIn: yup
            .object()
            .nullable()
            .shape({
                label: yup.string().required(t`MessageOptIn is required`),
                value: yup.string().required(t`MessageOptIn is required`),
            }),
        tags: yup
            .array()
            .nullable()
            .of(
                yup.object().shape({
                    id: yup.string().required(t`Tag is required`),
                })
            ),
    });
};

export interface UploadContactFormProps {
    onSuccess?: (importContact: ImportContact) => void;
    onFailure?: (importContact: ImportContact) => void;
    onRetry?: () => void;
    onFormDirty?: (isDirty: boolean) => void;
    defaultValues?: Partial<ImportNewContactFormType>;
    disableFileChange?: boolean;
    importDetails?: ImportContact;
}

const UploadContactForm: React.FC<UploadContactFormProps> = (props) => {
    const { _ } = useLingui();
    const { onSuccess, onFailure, onFormDirty, onRetry, disableFileChange, importDetails: importData } = props;
    const accountId = useAccountId();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isParsingModalOpen, onOpen: onParsingModalOpen, onClose: onParsingModalClose } = useDisclosure();
    const history = useHistory();
    const currentDate = dayjs(new Date()).format("DD MMM YY, hh:mmA ");
    const entityVersion = useGetEntityImportVersion();
    const [importDetails, setImportDetails] = React.useState<ImportContact | undefined>(importData);

    const isNonEditable = importData && !(importData.status === "PARSING" || importData.status === "READY FOR MAPPING");
    const isEditable = !isNonEditable;
    const isError =
        importData?.status === "PARSING ERROR" ||
        importData?.status === "FAILED" ||
        importData?.status === "VALIDATION ERROR";

    const { mutate: postCreateImport, isLoading: isCreatingImport } = usePostCreateImport({
        accountId,
        version: entityVersion,
        onSuccess: callFnsInSequence(setImportDetails, onParsingModalOpen),
        showSuccessMessage: false,
    });

    const { mutate: updateImport, isLoading: isUpdatingImport } = useUpdateImport({
        accountId,
        onSuccess: (data) => {
            if (data.status === "READY FOR MAPPING") {
                onSuccess?.(data);
            }
            if (data.status === "PARSING") {
                onParsingModalOpen();
            }
        },
    });

    useListenImportUpdates({
        onGettingUpdates: (data) => {
            if (data.id !== importDetails?.id) return;
            const status = data.entityImport?.status;
            if (data.entityImport?.status === "READY FOR MAPPING" || data.step === "parsing_completed") {
                onSuccess?.(importDetails);
            } else if (
                (status === "FAILED" || status === "PARSING ERROR" || status === "VALIDATION ERROR") &&
                data.entityImport
            ) {
                onFailure?.(data.entityImport);
            }
        },
    });

    const { control, register, errors, handleSubmit, formState, watch, reset, setValue } =
        useForm<ImportNewContactFormType>({
            resolver: yupResolver(getImportNewContactFormSchema()),
            defaultValues: props.defaultValues,
            shouldUnregister: false,
        });

    React.useEffect(() => {
        setValue("fileId", importData?.fileId);
    }, [importData?.fileId]);

    const goToImportHistory = () => history.push("/contacts/import/advanced");
    React.useEffect(() => {
        onFormDirty?.(formState?.isDirty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState?.isDirty]);
    const onSubmit: SubmitHandler<ImportNewContactFormType> = (data) => {
        const { file, name, tags, messageOptIn, importType } = data;
        if (!importData?.id) {
            postCreateImport({
                fileId: file.id,
                name,
                version: entityVersion,
                tags,
                messageOptIn: messageOptIn.value as unknown as boolean,
                importType: importType.value,
                entity: "CONTACT",
            });
        } else {
            updateImport({
                importId: importData.id,
                tags,
                messageOptIn: messageOptIn.value as unknown as boolean,
                importType: importType.value,
                name,
            });
        }
        trackEvent({
            event: "CTCS Upload File Next Clicked",
            properties: {
                contact_tags: data.tags.length > 0 ? "Added" : "Not Added",
                import_type: data.importType.value === "CREATE" ? "New" : "Update",
                marketing_opt_in: data.messageOptIn ? "True" : "False",
            },
        });
    };

    const retry = () => {
        reset({});
        onRetry?.();
    };
    const file = watch("file");
    const importType = watch("importType");
    return (
        <VStack align="flex-start" spacing={5} w="full" h="full" as="form" onSubmit={handleSubmit(onSubmit)}>
            {isError ? <ImportErrorInfo importData={importData} onRetry={retry} /> : <AvoidCommonErrorInfo />}
            <Box borderStyle="dashed" borderWidth={2} h="160px" w="full" borderRadius="6px" backgroundColor="gray.100">
                <VStack justify="center" h="full" mx={32}>
                    <Text fontSize="md" fontWeight="medium">
                        <Trans>Drag & drop your file here</Trans>
                    </Text>

                    <FileComponent
                        name={"file"}
                        accountId={accountId}
                        control={control}
                        isRequired={true}
                        accept={entityVersion === "V2" ? ".xlsx" : ".csv,.xlsx"}
                        maxFileSize={ENTITY_IMPORT_MAX_FILE_SIZE}
                        error={errors?.file?.url?.message}
                        disableUploadIcon={disableFileChange}
                        disableDeleteBtn={disableFileChange}
                        onChange={(file) => {
                            if (!file) {
                                trackEvent({ event: "CTCS File Delete Clicked", properties: { state: "Success" } });
                            } else trackEvent({ event: "CTCS File Import", properties: { state: "Success" } });
                        }}
                    />
                    <Text fontSize="xs" color="gray.500" textAlign="center">
                        {entityVersion === "V2"
                            ? "Accepted file type: .XLSX in less than 15MB"
                            : "Accepted file type: .CSV,.XLSX  in less than 15MB"}
                        <br />
                        (You can upload up to 100k contacts)
                    </Text>
                    {/* LANGFIX */}
                </VStack>
            </Box>

            {file && (
                <>
                    <Input
                        label={<Trans>Import Name</Trans>}
                        defaultValue={currentDate}
                        labelFieldOrientation="horizontal"
                        name="name"
                        placeholder={_(msg`Enter name`)}
                        register={register}
                        registerProps={{ required: _(msg`Import name is required`) }}
                        required
                        error={errors.name?.message}
                        autoComplete="none"
                        formControlProps={{ flex: 1 }}
                        isDisabled={isNonEditable}
                        size="sm"
                        boxShadow="0 1px 2px 0 rgba(0,0,0,0.05)"
                        bg="white"
                    />

                    <ReactSelectV2<LabelValue>
                        label={<Trans>Import Type</Trans>}
                        options={getImportTypeOptions() as unknown as LabelValue[]}
                        labelFieldOrientation="horizontal"
                        name="importType"
                        placeholder={_(msg`Select...`)}
                        control={control}
                        error={errors.importType?.label?.message}
                        isClearable
                        menuPlacement="auto"
                        isDisabled={isNonEditable}
                        isV2
                        required
                        isSearchable={false}
                        helpText={<Trans>Specify if you are importing new or both new and existing contacts</Trans>}
                        data-cy="advanced-import-marketingoptin-select-input"
                        mb={0}
                    />
                    {importType?.value && <ImportInfoCompInfo type={importType.value} />}

                    <ReactSelectV2
                        label={<Trans>Marketing Opt-In</Trans>}
                        tooltipText={
                            <Trans>
                                The selected marketing opt-in will be applied only for new contacts and ignored for
                                existing contacts.
                            </Trans>
                        }
                        options={getMessageOptInOptions() as unknown as LabelValue[]}
                        labelFieldOrientation="horizontal"
                        name="messageOptIn"
                        placeholder={_(msg`Select...`)}
                        control={control}
                        isDisabled={isNonEditable}
                        error={errors.messageOptIn?.label?.message}
                        defaultValue={getMessageOptInOptions()[0] as unknown as LabelValue}
                        menuPlacement="auto"
                        isV2
                        isSearchable={false}
                        mb={0}
                        helpText={
                            <Text color="gray.400" fontSize="xs">
                                <Trans>Provide the new contacts consent to receive marketing messages.</Trans>{" "}
                                <LearnMoreLink
                                    module="contacts"
                                    titleName="Import Contact Upload File Marketing Opt-In"
                                    buttonType="hyperlink"
                                    fontWeight="normal"
                                    variant="link"
                                    colorScheme="brand"
                                    link={marketingOptinDocURL}
                                    data-cy="advanced-import-marketingoptin-learn-more-link"
                                />
                            </Text>
                        }
                        data-cy="advanced-import-marketingoptin-select-input"
                    />
                    <Accordion allowToggle w="full">
                        <AccordionItem border="none">
                            <AccordionButton
                                _hover={{ bg: "transparent", color: "brand.500" }}
                                px={0}
                                data-cy="integration-template-advanced-options-accordion"
                            >
                                <Box textAlign="left" fontSize="sm" color="blue.500">
                                    <Trans>Add Contact Tags</Trans>
                                </Box>
                                <AccordionIcon color="blue.500" />
                            </AccordionButton>
                            <AccordionPanel p={4} bg="gray.100" rounded="md" borderWidth="1px" borderColor="gray.300">
                                <FormControlWrapper
                                    label={<Trans>Contact Tags</Trans>}
                                    labelFieldOrientation="horizontal"
                                    isOptional
                                    tooltipText={
                                        <Trans>
                                            The tags added here will get appended by default to all the imported
                                            contacts.
                                        </Trans>
                                    }
                                    helpText={
                                        <Trans>
                                            Add tags to all these contacts, this will help you identify and create
                                            segments easily later.
                                        </Trans>
                                    }
                                >
                                    <Controller
                                        name="tags"
                                        control={control}
                                        render={({ onChange, value }) => {
                                            return (
                                                <TagMultiSelect
                                                    onSelect={(selectedTags) => onChange(selectedTags)}
                                                    defaultValue={value}
                                                    isCreatable
                                                    isDisabled={isNonEditable}
                                                />
                                            );
                                        }}
                                    />
                                </FormControlWrapper>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                    {isEditable && (
                        <ButtonGroup size="sm" alignSelf="flex-end">
                            <Button
                                variant="outline"
                                bg="white"
                                onClick={formState?.isDirty ? onOpen : goToImportHistory}
                            >
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button colorScheme="brand" type="submit" isLoading={isCreatingImport || isUpdatingImport}>
                                <Trans>Next</Trans>
                            </Button>
                        </ButtonGroup>
                    )}
                    {isError && (
                        <Button colorScheme="brand" size="sm" alignSelf="flex-end" onClick={retry}>
                            <Trans>Retry</Trans>
                        </Button>
                    )}
                </>
            )}
            {importDetails?.id && (
                <ReadyForMappingModal
                    isOpen={isParsingModalOpen}
                    onClose={onParsingModalClose}
                    importId={importDetails.id}
                    onReadyForMapping={onSuccess}
                    onFailure={onFailure}
                />
            )}
            <ExitAlert isOpen={isOpen} onClose={onClose} onCancel={onClose} onOk={goToImportHistory} />
        </VStack>
    );
};

export default React.memo(UploadContactForm);
