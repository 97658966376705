import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useAccount } from "app/utils/react-helpers";
import React from "react";
import { GroupedOptionsType } from "react-select";
import { AsyncProps } from "react-select/async";
import { PopUpNavigationAsyncSelect } from "./PopUpNavigationAsyncSelect";
import { PopUpNavigationSelect } from "./PopUpNavigationSelect";
import { Option } from "./types";
import { callFnsInSequence } from "app/utils/common";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { t } from "@lingui/macro";

export interface IPopUpNavigator {
    isOpen: boolean;
    onClose: () => void;
    placeholderValue?: string;
    options?: GroupedOptionsType<Option>;
    loadOptions?: AsyncProps<Option>["loadOptions"];
}

export const getDefaultValue = () => {
    return {
        placeholderValue: t`Where would you like to go?`,
    };
};

export const PopUpNavigator: React.FC<IPopUpNavigator> = (props) => {
    const { isOpen, onClose, placeholderValue, options, loadOptions } = props;
    const account = useAccount();

    const removeAccountsMenu = React.useMemo(
        () => account?.onboardingState === "new" || account?.onboardingState === "sandbox",
        [account?.onboardingState]
    );

    if (!options && !loadOptions) {
        console.log("Please provide an options or load options(ASYNC)");
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={callFnsInSequence(onClose, () => trackEvent({ event: "CTRL Escape Clicked" }))}
            size={"xl"}
        >
            <ModalOverlay backgroundColor={"blackAlpha.700"} />
            <ModalContent h="375px" p="4">
                {loadOptions ? (
                    <PopUpNavigationAsyncSelect
                        onClose={onClose}
                        placeholderValue={placeholderValue}
                        loadOptions={loadOptions}
                        filterOption={(o, input) => {
                            if (
                                o.label.toLowerCase().includes(input.toLocaleLowerCase()) ||
                                o.data.groupName.toLowerCase().includes(input.toLocaleLowerCase())
                            ) {
                                if (!removeAccountsMenu) return true;
                                return o.data.groupName !== "ACCOUNT";
                            }
                            return false;
                        }}
                    />
                ) : (
                    <PopUpNavigationSelect
                        onClose={onClose}
                        placeholderValue={placeholderValue}
                        options={options ?? []}
                        filterOption={(o, input) => {
                            if (
                                o.label.toLowerCase().includes(input.toLocaleLowerCase()) ||
                                o.data.groupName.toLowerCase().includes(input.toLocaleLowerCase())
                            ) {
                                if (!removeAccountsMenu) return true;
                                return o.data.groupName !== "ACCOUNT";
                            }
                            return false;
                        }}
                    />
                )}
            </ModalContent>
        </Modal>
    );
};
