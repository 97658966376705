import { HStack, Icon, ListItem, Text, UnorderedList, VStack } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import React from "react";
import { RiAlertFill } from "react-icons/ri";

const AvoidCommonErrorInfo: React.FC = () => {
    return (
        <VStack w="full" spacing={4}>
            <VStack
                w="full"
                align="start"
                p={4}
                borderColor="gray.200"
                borderWidth="1px"
                borderRadius="lg"
                spacing={0.5}
                bg="orange.50"
            >
                <HStack w="full" justify="start">
                    <Icon as={RiAlertFill} color="red.500" />
                    <Text color="gray.700" fontWeight="medium">
                        <Trans>Avoid common errors</Trans>
                    </Text>
                </HStack>
                <UnorderedList fontSize="sm" color="gray.500" ml={10}>
                    <ListItem>
                        <Trans>
                            If you see the phone number in the cell, e.g. &quot;8.5265E+10&quot;, please right click
                            &quot;Format&quot; and change the data cell to &quot;numbers&quot; with zero decimal place.
                        </Trans>
                    </ListItem>
                    <ListItem>
                        <Trans>Remove columns that you do not wish to import before uploading</Trans>
                    </ListItem>
                </UnorderedList>
            </VStack>
        </VStack>
    );
};

export default AvoidCommonErrorInfo;
