import { trackEvent } from "app/hooks/productAnalytics/tracker";
import useLocalStorage from "app/hooks/useLocalStorage";
import { RootState } from "app/modules";
import { logoutUser } from "app/modules/authed";
import captureException from "app/utils/captureException";
import { deleteJSON } from "app/utils/fetchUtils";
import { useAccountId } from "app/utils/react-helpers";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { closeSupportTicketWidget } from "../Widget/utils";
import { Grid, useToast } from "@chakra-ui/react";
import { useQueryParam } from "app/utils/history";
import LoadingState from "app/components/LoadingState";
import { removeElementById } from "app/utils/common";

const destroyProductFruits = () => {
    window.productFruits?.services?.destroy?.();
};

const Logout: React.FC = () => {
    const user = useSelector((state: RootState) => state.authed.user);
    const toast = useToast();
    const [isLoggedOut, setLoggedOut] = React.useState(false);
    const query = useQueryParam();
    const reason = query.get("reason");

    const history = useHistory();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const [pushConfig] = useLocalStorage<{
        i?: string;
        u?: string;
        t?: string;
        a?: string;
        s?: "unknown" | "loading" | "success" | "failed";
    }>("pushConfig", { s: "unknown" }, {});

    const onLogoutSuccess = () => {
        setLoggedOut(true);
    };
    const onLogoutFailure = (err: Error) => {
        toast({
            title: "Something went wrong",
            description: err.message,
            status: "error",
        });
        history.push("/home");
    };

    React.useLayoutEffect(() => {
        const wasPushNotificationConfigured = Boolean(pushConfig?.s === "success" && pushConfig?.i);
        const wasLoggedoutFromUnknownReasons = Boolean(
            !reason || !["password-change", "account-not-found"].includes(reason)
        );
        const isValidAccountAndUser = Boolean(accountId && user?.id);
        const shouldDeleteDevice =
            wasPushNotificationConfigured && wasLoggedoutFromUnknownReasons && isValidAccountAndUser;

        if (shouldDeleteDevice) {
            deleteJSON(`/api/account/${accountId}/users/${user.id}/devices/${pushConfig.i}`).catch((e) => {
                captureException(e);
            });
        }

        trackEvent({
            event: "USER_SIGNOUT",
            properties: {
                UserId: user.id,
                UserName: user.name,
            },
        });

        closeSupportTicketWidget();

        dispatch(logoutUser(onLogoutSuccess, onLogoutFailure));

        destroyProductFruits();

        if (reason == "password-change") {
            toast({
                title: "Session Updated",
                description: "Your session has been expired, so you were logged out.",
                status: "info",
                duration: 9000,
                isClosable: true,
            });
        }

        removeElementById("miitelPhoneIFrameButton");
        removeElementById("miitelPhoneIFrameOuter");
        removeElementById("miitelScript");
    });

    if (!isLoggedOut)
        return (
            <Grid w="full" h="100vh">
                <LoadingState isCentered title="Logging out...." />
            </Grid>
        );
    return <Redirect to={{ pathname: "/login" }} />;
};

export default Logout;
